// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();
// Test
/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Insights Slider
   */

  function initSlider() {
    if (
      $(window).width() <= 1024 &&
      !$('.insights__slider').hasClass('slick-initialized')
    ) {
      $('.insights__slider').slick({
        slidesToShow: 2,
        responsive: [
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    } else if (
      $(window).width() > 1024 &&
      $('.insights__slider').hasClass('slick-initialized')
    ) {
      $('.insights__slider').slick('unslick');
    }
  }

  initSlider();

  $(window).on('resize', function () {
    initSlider();
  });
  /**
   * Testimonials Slider
   */
  $('.js-detail-slider').slick({
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  $('.testimonials__slider').slick({
    centerMode: true,
    centerPadding: '400px',
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerMode: true,
          centerPadding: '200px',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          centerPadding: '100px',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1,
        },
      },
    ],
  });

  // blog-posts load more
  $('#load-more-blog').on('click', function () {
    var button = $(this);
    let offset = $('.posts-list-item').length;
    console.log(offset);
    $.ajax({
      url: ajax_object.ajax_url,
      type: 'post',
      data: {
        action: 'load_more_posts',
        nonce: ajax_object.ajax_url.nonce,
        offset: offset,
      },
      beforeSend: function () {
        button.text('Loading...');
      },
      success: function (response) {
        $('.js-post-list').append(response.html);
        button.text('View More');

        if (!response.has_more) {
          button.hide();
        }
      },
      error: function () {
        button.text('View More');
        console.log('err');
      },
    });
  });
  /**
   * Add active class to toc list
   */
  $('.toc a').on('click', function (event) {
    $('.toc a').removeClass('active');
    $(this).addClass('active');
    event.preventDefault();
    var target = $(this.hash);
    if (target.length) {
      window.scrollTo({
        top: target.offset().top - 180,
        behavior: 'smooth',
      });
    }
  });
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    threshold: 0.97,
    once: true,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  $('.faq__item-question').click(function () {
    // $('.faq__item-question').removeClass('active');
    let item = $(this).parent();
    let answer = item.find('.faq__item-answer');
    $(this).toggleClass('active');
    answer.slideToggle();
  });

  // Publications redirect
  $(document).on('click', '.download-button', function () {
    const parent = $(this).parents('.publications__item');
    const imageSrc = parent.find('.publicaion-image').attr('src');
    const fileUrl = $(this).attr('data-link');
    const formContainer = $($(this).attr('href'));
    formContainer.find('.hidden-file-url').val(fileUrl);
    $('.fancybox-container .popup-image').attr('src', imageSrc);
  });

  $('[data-fancybox]').fancybox({
    afterClose: function (instance, current) {
      $('#popup-form img').attr('src', '');
    },
  });

  $(document).on('gform_confirmation_loaded', function (event, formId) {
    if (formId === 3) {
      const formContainer = $('.fancybox-container');
      const fileUrl = $('.hidden-file-url').val();
      if (fileUrl) {
        window.location.href = fileUrl;
      }
    }
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
let header = document.querySelector('header');
let lastScrollY = window.scrollY;
$(window).on('scroll', function () {
  // jQuery code goes here
  if (window.scrollY > lastScrollY) {
    header.style.top = '0';
  } else if (window.scrollY === 0) {
    header.style.top = '35px';
  }
  lastScrollY = window.scrollY;
});
